import React from 'react';
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';
import apiHelpers from '../../../utils/apiHelpers';

// const SelectUserRole = props => {
//   const [options, setOptions] = useState([]);

//   const [input, setInput] = useState('');

//   const [value, setValue] = useState({});

//   useEffect(() => {
//     let { value } = props;
//     let userValue;
//     if (value) {
//       userValue = options.find(option => option.id === value.id);
//       if (!userValue && value.id) loadDefaultOptions(value);
//     } else {
//       loadDefaultOptions('');
//     }
//   }, [props.value]);

//   const handleChange = selectedValue => {
//     setValue(selectedValue);
//     let event = { target: {} };
//     event.target.name = props.name ? props.name : 'select-options-nameless';
//     event.target.value = selectedValue ? selectedValue.value : null;
//     event.target.type = 'react-select';
//     props.onChange(event);
//   };

//   const loadOptions = (input, callback) => {
//     if (!input.length) return;

//     let filters = {
//       where: {
//         userRole: input,
//       },
//     };

//     apiHelper
//       .getUserRoles(filters)
//       .then(response => {
//         let entitiesForSelect = response.data.map(entity => {
//           return {
//             value: entity,
//             label: entity.userRole,
//           };
//         });

//         setOptions(entitiesForSelect);
//         callback(entitiesForSelect);
//       })
//       .catch(e => {
//         console.log(e);
//       });
//   };

//   const loadDefaultOptions = value => {
//     if (!value.id) return;

//     let filters = {
//       where: {
//         id: value.id,
//       },
//     };

//     apiHelper
//       .getUserRoles(filters)
//       .then(response => {
//         let entitiesForSelect = response.data
//           .sort((a, b) => {
//             if (a.id > b.id) return 1;
//             if (a.id < b.id) return -1;
//             return 0;
//           })
//           .map(entity => {
//             return {
//               value: entity,
//               label: entity.userRole,
//             };
//           });

//         setOptions(entitiesForSelect);
//         setValue({ value: value, label: value.userRole });
//       })
//       .catch(e => {
//         console.log(e);
//       });
//   };

//   return (
//     <AsyncSelect
//       onChange={handleChange}
//       loadOptions={loadOptions}
//       value={value}
//       name={props.name}
//       required={props.required}
//       defaultOptions
//       placeholder="Rol"
//       noOptionsMessage={() => 'No se encontraron roles con el nombre ingresado'}
//     />
//   );
// };

const SelectUserRole = ({
  value,
  disabled,
  onChange,
  name,
  menuPlacement = 'bottom',
}) => {
  const [options, setOptions] = useState([]);
  const loadOptions = (inputValue, callback) => {
    apiHelpers.getUserRoles().then(response => {
      const roles = response.data;
      const filtered = roles.filter(p =>
        p.userRole.toLowerCase().includes(inputValue.toLowerCase()),
      );
      const values = filtered.map(f => {
        return { value: f.id, label: f.userRole };
      });
      setOptions(values);
      callback(values);
    });
  };
  const handleChange = (e, action) => {
    onChange({
      target: { value: { id: e.value, userRole: e.label }, name: name },
    });
  };

  return (
    <AsyncSelect
      menuPlacement={menuPlacement}
      onChange={handleChange}
      isDisabled={disabled}
      loadOptions={loadOptions}
      defaultOptions
      placeholder="Rol"
      value={options.find(o => o.value == value.id)}></AsyncSelect>
  );
};

export default SelectUserRole;
